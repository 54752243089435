<template>
  <div class="app">
    <el-dialog
      :close-on-click-modal="false"
      v-model="daoruVisible"
      title="导入"
      width="40%"
      destroy-on-close
    >
      <el-form size="small" label-position="right" label-width="5px">
        <el-form-item label="">
          <el-input
            style="width: 70%; margin-right: 10px"
            v-model="path"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            accept=".xlsx, .xls, .xml"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >请选择文件</el-button
          >
        </el-form-item>
      </el-form>
      <div>
        <a href="javascript:;" @click="loadForm()">下载模板</a>
        <div style="text-align: right">
          <el-button
            size="small"
            type="primary"
            @click="uploadExcel"
            v-loading="daoruLoading"
            >上传</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      destroy-on-close
      title="线索"
      v-model="dialogTableVisible"
    >
      <div style="margin-bottom:10px;">
        <span style="color: #67c23a;margin-right:20px"
          >导入成功条数:{{ callList.succCount }}</span
        >
        <span style="color:#F15A24;margin-right:20px"
          >导入失败条数:{{ callList.failCount }}</span
        >
        <span style="color:#F15A24" v-if="callList.errMsg"
          >导入失败原因:{{ callList.errMsg }}</span
        >
      </div>
      <div style="height: 450px;overflow: auto;">
        <el-table :data="callList.list" size="small" border>
            <el-table-column
            align="center"
            property="customerName"
            label="客户名称"
          ></el-table-column>
          <!-- <el-table-column
            align="center"
            property="contractNum"
            label="导入失败合同编号"
          ></el-table-column> -->
          <el-table-column
            align="center"
            property="remark"
            label="原因"
          ></el-table-column>
        </el-table>
      </div>
      <!-- <div style="margin: 15px 0; padding-bottom:10px">
        <el-button
          style="float: right;margin-left:10px"
          @click="cancel"
          size="small"
          type="primary"
          >关闭</el-button
        >
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import daoruContract from "../../../crm/contract/components/daoruContract.vue";
import { importExcel } from "@/api/newCrm.js";

export default {
  name: '',
  components: {
    daoruContract
  },
  props: {},
  data () {
    return {
      daoruVisible: false,
      path: '',
      daoruLoading: false,
      rawFile:'',
      callList:[],
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {
    init(){
      this.daoruVisible = true;
      this.path = '';
      this.rawFile = '';
    },
    // 下载模板
    loadForm() {
      window.open(
        "https://file.listensoft.net/mnt/v2/other/线索导入模板.xlsx" +
          "?" +
          new Date().getTime()
      );
    },

    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },

    // 选择文件
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },

    // 上传文件
    uploadExcel() {
      if(!this.rawFile){
        this.$qzfMessage('请选择文件',1)
        return
      }
      this.daoruLoading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      importExcel(param).then((res) => {
        this.daoruLoading = false;
        if (res.data.msg == "success") {
          this.path = ''
          this.rawFile = '';
          this.$emit('success')
          this.$qzfMessage("上传成功")
          this.daoruVisible = false;
          this.callList = res.data.data;
          this.dialogTableVisible = true;
        }
      });
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning",
      });
      return false;
    },
    cancel() {
      this.dialogTableVisible = false
    },
  }
}
</script>

<style scoped lang="less"></style>
