<template>
  <el-select
    v-model="userId"
    placeholder="选择任务类型"
    style="width: 150px;margin-left:0"
    @change="changeValue"
    size="small"
  >
    <el-option label="" :value="0" @click="addTask">
      <span style="color:var(--themeColor,#17a2b8)">+新增任务类型</span>
    </el-option>
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.taskTypeName"
      :value="item.id"
    />
  </el-select>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogTypeVisible"
    title="添加任务类型"
    width="400px"
    destroy-on-close
  >
    <el-form :model="temp">
      <el-form-item label="任务类型：" :label-width="formLabelWidth">
        <el-input
          v-model="temp.taskTypeName"
          style="width: 200px"
          size="small"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogTypeVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="saveType()" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { taskTypeList, saveTaskType } from "@/api/crm";
export default {
  name: "selectTaskType",
  components: {},
  props: {
    userId: Number,
    hidden: Boolean,
  },
  data() {
    return {
      options: [],
      dialogTypeVisible: false,
      temp: {
        taskTypeName: "",
      },
    };
  },
  computed: {
    userId: {
      get() {
        if (!this.userId) {
          return null;
        }
        return this.userId;
      },
      set(val) {
        this.$emit("update:userId", val);
        this.$emit("success", val);
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      taskTypeList({}).then((res) => {
        this.options = res.data.data;
      });
    },
    addTask() {
      this.$emit("update:userId", null);
      this.$emit("success", null);
      this.dialogTypeVisible = true;
      this.temp.taskTypeName = "";
    },
    saveType() {
      if (!this.temp.taskTypeName) {
        this.$qzfMessage("请输入名称", 1);
        return;
      }
      saveTaskType(this.temp).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("添加成功");
          this.init();
          this.$emit("update:userId", res.data.data.info.id);
          this.$emit("success", res.data.data.info.id);
          this.dialogTypeVisible = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
