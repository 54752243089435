<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    :title="dialogTitle"
    width="600px"
  >
    <el-scrollbar :maxHeight="contentStyleObj">
      <el-form
        :inline="true"
        label-width="95px"
        :rules="rules"
        :model="form"
        ref="ruleForm"
        size="small"
      >
        <el-form-item label="客户名称：" prop="customerName">
          <el-autocomplete
            v-model="form.customerName"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            style="width: 394px"
            placeholder="请输入客户名称"
          />
        </el-form-item>
        <el-form-item label="联系人：" prop="linkman">
          <el-input
            placeholder="请输入联系人"
            v-model="form.linkman"
            style="width: 150px"
          />
        </el-form-item>
        <el-form-item label="联系电话：" prop="linkmanTel">
          <el-input
            placeholder="请输入联系电话"
            v-model="form.linkmanTel"
            style="width: 150px"
          />
        </el-form-item>
        <el-form-item label="截止日期：" prop="endTime">
          <el-date-picker
            v-model="form.endTime"
            type="date"
            placeholder="请选择截止日期"
            style="width: 150px"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="任务类型：" prop="taskTypeId">
          <selectTaskType v-model:userId="form.taskTypeId" />
        </el-form-item>
        <el-form-item label="任务名称：" prop="taskName">
          <el-input
            placeholder="请输入任务名称"
            v-model="form.taskName"
            type="textarea"
            style="width: 394px"
          />
        </el-form-item>
        <div>
          <el-form-item label="图片附件：">
            <div class="bigimg" style="margin-left: 0">
              <el-button type="primary" size="small">
                <input
                  @change="uploadimg($event)"
                  class="inputfl"
                  type="file"
                  name="upfile"
                  accept=".png, .jpg, .jpeg"
                  multiple
                />上传图片
              </el-button>
            </div>
            <div class="archives_up">
              <div
                class="pic"
                v-for="(item, index) in img"
                :key="index"
                v-show="img.length != 0"
                style="margin-right: 10px"
              >
                <img :src="item" style="height: 80px" @click="checkImg(item)" />
                <a class="del_btn" @click="delImg(index)">x</a>
              </div>
            </div>
          </el-form-item>
        </div>

        <el-form-item label="文件附件：">
          <el-input
            style="width: 230px; margin-right: 10px"
            v-model="path"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            @change="handleClick"
            multiple
            accept=".xlsx, .xls, .xml,.pdf,.doc,.docx"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >请选择文件</el-button
          >
          <div v-show="uploadFile.length != 0" class="upload_file">
            <div v-for="(item, index) in uploadFile" :key="index" class="pic">
              <el-icon style="color: var(--themeColor, #17a2b8)"
                ><DocumentChecked
              /></el-icon>
              <div class="file_name">
                {{ filterFile(item) }}
              </div>
              <el-button
                type="danger"
                icon="Delete"
                link
                size="small"
                @click="delFile(index)"
                class="del_file"
              ></el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="saveSure()" size="small">
          确 定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="showImg"
  />
</template>

<script>
import { saveTask, getCustomerList } from "@/api/crm";
import selectTaskType from "./selectTaskType.vue";
import axios from "axios";
export default {
  name: "addTask",
  components: {
    selectTaskType,
  },
  props: {},
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        return callback();
      }
    };
    return {
      dialogFormVisible: false,
      form: {
        customerName: "",
        linkman: "",
        linkmanTel: "",
        taskTypeId: null,
        taskName: "",
        endTime: "",
        annex: "",
        annexImgs: [],
      },
      rules: {
        taskName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
        customerName: [
          { required: true, message: "请输入客户名称", trigger: "blur" },
        ],
        linkman: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        taskTypeId: [
          {
            required: true,
            message: "请选择任务类型",
            trigger: "change",
          },
        ],
        linkmanTel: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
        endTime: [
          {
            required: true,
            message: "请选择截止日期",
            trigger: "change",
          },
        ],
      },
      img: [],
      showImagePreview: false,
      showImg: [],
      path: "",
      uploadFile: [],
      dialogTitle: "新增任务",
    };
  },

  mounted() {
    this.contentStyleObj = this.$getHeight(280);
  },
  methods: {
    init(row, type) {
      this.resetForm();
      if(row){
        this.form.contractId = row.id
      }
      if (row && type == "contract") {
        this.dialogTitle = "转外勤";
        this.form.customerName = row.customerName;
        this.form.linkman = row.contactsName;
        this.form.linkmanTel = row.contactsPhone;
      } else if (row && type == "edit") {
        this.dialogTitle = "编辑任务";
        this.form = Object.assign({}, row);
        this.img = row.annex ? row.annexImgs : [];
        this.uploadFile = row.file ? row.file.split(",") : [];
      }
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["ruleForm"].clearValidate();
      });
    },
    saveSure() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.form.annexImgs = this.img;
          this.form.file = this.uploadFile.join(",");
          saveTask(this.form).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("操作成功");
              this.$emit("success");
              this.dialogFormVisible = false;
            }
          });
        }
      });
    },
    resetForm() {
      this.form = {
        customerName: "",
        linkman: "",
        linkmanTel: "",
        taskTypeId: null,
        taskName: "",
        endTime: "",
        annex: "",
      };
      this.img = [];
      this.uploadFile = [];
      this.path = "";
      this.dialogTitle = "新增任务";
    },
    //上传文件
    handleClick(e) {
      this.filesList = e.target.files;
      this.path = "";
      for (let i = 0; i < this.filesList.length; i++) {
        this.path += this.filesList[i].name + ",";
      }
      this.uploadExcel();
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    async uploadExcel() {
      if (this.filesList.length == 0) {
        this.$qzfMessage("请上传文件", 1);
        return;
      }
      for (let k = 0; k < this.filesList.length; k++) {
        const isLt3M = this.filesList[k].size / 1024 / 1024 < 10;
        if (!isLt3M) {
          this.$qzfMessage("请不要上传大于10M的文件。", 1);
          return;
        }
      }
      for (let i = 0; i < this.filesList.length; i++) {
        const param = new FormData(); // 创建form对象
        let title = this.filesList[i].name.replace(/,/g, "");
        param.append("upFile", this.filesList[i], title); // 单个图片 ，多个用循环 append 添加
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        await axios
          .post("https://public.listensoft.net/api/uploadImg", param, config)
          .then((res) => {
            this.loading = false;
            if (res.data.msg == "success") {
              let url = "https://file.listensoft.net" + res.data.data.url;
              this.uploadFile.push(url);
            }
          })
          .catch(() => {});
      }
      this.$qzfMessage("上传成功");
    },
    //input输入框
    querySearchAsync(queryString, cb) {
      if (queryString == "") {
        return;
      }
      getCustomerList({ customerName: queryString, customerType: "2" }).then(
        (res) => {
          let arr = [];
          res.data.data.list.map((v) => {
            arr.push({
              value: v.customerName,
              linkmanTel: v.linkmanTel,
              linkman: v.linkman,
            });
          });
          cb(arr);
        }
      );
    },
    handleSelect(item) {
      this.form.linkman = item.linkman;
      this.form.linkmanTel = item.linkmanTel;
    },
    //上传图片
    uploadimg(event) {
      this.files = event.target.files;
      this.addSub();
    },
    async addSub() {
      if (this.files.length == 0) {
        this.$qzfMessage("请上传图片", 1);
        return;
      }
      for (let k = 0; k < this.files.length; k++) {
        const isLt3M = this.files[k].size / 1024 / 1024 < 10;
        if (!isLt3M) {
          this.$qzfMessage("请不要上传大于10M的文件。", 1);
          return;
        }
      }
      for (let i = 0; i < this.files.length; i++) {
        const param = new FormData(); // 创建form对象
        let title = this.files[i].name.replace(/,/g, "");
        param.append("upFile", this.files[i], title); // 单个图片 ，多个用循环 append 添加
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        await axios
          .post("https://public.listensoft.net/api/uploadImg", param, config)
          .then((res) => {
            const imgs = res.data.data;
            this.img.push("https://file.listensoft.net" + imgs.url);
          })
          .catch(() => {});
      }
      this.$qzfMessage("上传成功");
    },
    //删除图片
    delImg(index) {
      this.img.splice(index, 1);
    },
    checkImg(item) {
      let arr = [];
      arr.push(item);
      this.img.map((v) => {
        if (v != item) {
          arr.push(v);
        }
      });
      this.showImg = arr;
      this.showImagePreview = true;
    },
    //删除文件
    delFile(index) {
      this.uploadFile.splice(index, 1);
    },
    filterFile(type) {
      var match = type.match(/\/([^/?]+)$/);
      return match ? match[1] : null; // 返回匹配到的文件名，如果没有匹配到则返回null
    },
  },
};
</script>

<style scoped lang="scss">
.el-form-item__label {
  font-size: 13px;
}
.el-form--inline .el-form-item:nth-child(2n) {
  margin-right: 0;
}
.el-form--inline .el-form-item:last-child {
  margin-right: 0;
}
.archives_up {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
}
.bigimg {
  width: 100%;
  cursor: pointer;
}
.bigimg input {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
}
.pic {
  display: flex;
  margin-top: 5px;
  align-items: center;
  position: relative;
}
.del_btn {
  position: absolute;
  top: 0;
  right: -9px;
  color: red;
  cursor: pointer;
  line-height: normal;
}
.upload_file {
  margin-top: 5px;
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
}
.del_file {
  margin-left: 3px;
  color: #fe696a;
}
.file_name {
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
