<template>
  <div class="app">
    <el-dialog
      v-model="dialogVisible"
      title="分配"
      width="340"
      :close-on-click-modal="false"
      align-center
    >
      <div>
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" :inline="true">
          <el-form-item label="持有人:" prop="userId">
            <selectuser code="addCustomer" v-model:userId="form.userId" :multiples="false" style="width: 200px;margin: 0;" ></selectuser>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">取消</el-button>
          <el-button type="primary"  size="small" @click="save">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getAssign } from "@/api/newCrm.js";

export default {
  name: 'allocation',
  components: {},
  props: {},
  data () {
    return {
      form: {},//表单数据
      rules: {
        userId: [
          { required: true, message: '请选择持有人', trigger: 'blur' },
        ],
      },
      dialogVisible: false,
      ids: []
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {
    init(val){
      this.ids = val
      this.dialogVisible = true
    },
    save(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let param ={
            clueIds: this.ids,
            userId: this.form.userId
          }
          getAssign(param).then(res => {
            if (res.data.msg == 'success') {
              this.$qzfMessage("分配成功")
              this.dialogVisible = false
              this.$emit('success')
            }
            // this.options = res.data.data.list
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style scoped lang="less"></style>
